.settings {
  display: flex;

  .settingsContainer {
    flex: 19;
    height: 100vh;
    margin-left: 0px;
    // margin-right: 20px;
    align-items: center;
    width: 100%;
    // min-height: 100vh;

 @media (max-width: 768px) {
      margin-left: 0;
    }

    .settingsHeader {
      margin: 25px 0;
      display: flex;
      justify-content: center;
    }

    .settingsWrapper {
      display: flex;
      flex-wrap: wrap;
      gap:20px;
      flex-direction: row;
      justify-content: center;
      padding: 0 10vw;
      padding-bottom: 7vw;


      .settingsLeft {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex: 1;

        .formInput {
          width: 80%;
          margin: 10px auto;
          display: flex;
          flex-direction: column;
          label {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 12px;
            margin-bottom: 5px;
          }
          input {
            width: 100%;
            border: 1px solid #cccccc;
            border-radius: 5px;
            outline: none;
            padding: 10px;
            box-sizing: border-box;

            &:focus {
              outline: none;
            }
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          input[type="number"] {
            -moz-appearance: textfield;
          }
        }

        .settingsButton {
          // border: none;
          // padding: 10px 20px;
          // background-color: #dd1414;
          // color: white;
          // font-weight: bold;
          // cursor: pointer;
          // border-radius: 5px;
          background-color: #dd1414;
          color: white;
          border: none;
          font-weight: 500;
          padding: 10px 20px;
          font-size: 20px;
          border-radius: 15px;
          cursor: pointer;
          width: fit-content;
          margin-top: 10px;

          &:hover {
            background-color: #ff1c1c;
          }
        }
      }
      .settingsRight {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        flex: 1;

        // overflow-y: auto;
        // max-height: 65vh;

        .orders {
          margin: 10px auto;
          display: flex;
          flex-wrap: wrap;
          // flex-direction: column;
          gap: 10px;

          .orderItem {
            margin-top: 10px;
            cursor: pointer;
            padding: 10px;
            width: 100px;
            border: 1px solid #cccccc;
            border-radius: 5px;
            box-sizing: border-box;
            span {
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 12px;
              text-align: center;

              .icon {
                color: #dd1414;
              }
            }
          }
        }
      }

      .settingsTitle {
        font-size: 20px;
        text-align: center;
      }
    }
  }
}
