.alergeny {
  display: flex;

  .alergenyContainer {
    // position: relative;
    flex: 19;
    // margin-left: 75px;
    // margin-right: 20px;
    margin-bottom: 30px;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    // min-width: calc(100vw - 75px);
    // min-width: 90%;
    // position: absolute;

    @media (max-width: 768px) {
      margin-left: 0;
    }

    hr {
      margin-top: 10px;
      height: 0;
      border: 0.5px solid #efecec;
    }

    .alergenyWrapper {
      align-items: center;
      display: block;
      flex-wrap: wrap;
      justify-content: center;
      margin: 150px auto;
      padding: 0 50px;
      max-width: 900px;
      // height: 90vh;
      h4{
        margin: 10px;
      }
      span {
        display: block;
      }
    }
  }
}
