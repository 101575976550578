.hompageModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .modalBox {
    // width: 80%;
    // height: 80%;
    margin: auto;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    transition: transform 0.2s ease-in-out; /* Added transition for smooth hover effect */

    &:hover {
      transform: scale(1.05);
  }

@media (min-width: 768px) {
    &:hover {
      transform: none;
  }
  }

    .titleModalHompage {
      width: 100%;
      display: flex;
      gap: 50px;
      justify-content: space-between;
      .leftTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 20px;
      }
      .rightTitle {
        .closeButton {
          padding-right: 20px;
          background-color: transparent;
          border: none;
          font-size: 20px;
          cursor: pointer;
          &:hover {
            color: #dd1414;
          }
        }
      }
    }
    .modalBoxContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 auto;
      gap: 20px;
      p {
        max-width: 400px;
        text-align: center;
      }
      .addToCartButton {
        max-width: 150px;
        background-color: #dd1414;
        color: white;
        border: none;
        font-weight: 500;
        padding: 1vw;
        border-radius: 2vw;
        cursor: pointer;

        &:hover {
          background-color: #ff1c1c;
        }
      }
    }
  }

  .backgroundModal {
    position: fixed;
    background: #252424cc;
    height: 100%;
    width: 100%;
    z-index: -1;
  }
}

