.order {
  position: fixed;
  top: 0;
  left: 0;
  background: #25242482;
  height: 100%;
  width: 100vw;
  z-index: 9999;
  display: flex;
  align-items: center;
  overflow-y: auto;

  .orderContainer {
    background-color: white;
    // height: 75vh;
    // max-width: 80vw;
    height: 100%;
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 5vw;
    overflow-y: auto;
    margin-top: 2vw;

    .top {
      padding: 10px;
      margin: 1vw;
      display: flex;
      justify-content: space-between;

      .info {
        display: flex;
        flex-direction: column;

        h1 {
          font-size: clamp(10px, 3vw, 20px);
        }
        p{
          font-size: clamp(10px, 2.5vw, 16px);
        }
      }

      .closeButton {
        background-color: transparent;
        border: none;
        font-size: 25px;
        cursor: pointer;
        &:hover {
          color: #dd1414;
        }
      }
    }

    .orderWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      -webkit-box-shadow: 2px 4px 10px 1px rgba(0, 0, 0, 0.47);
      box-shadow: 2px 4px 10px 1px rgba(201, 201, 201, 0.47);
      margin: 0 7.5vw;
      margin-bottom: 30px;
      padding: 20px 0;

      .orderItems {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
        margin: 0 10%;
      }
    }
  }
}
