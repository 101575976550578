@keyframes slideInFromLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(300%);
  }
}

.addedToCartContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;

  .addedToCartInfo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 2px solid #333;
    border-radius: 2vw;
    padding: 3vw;
    animation: 0.5s ease-out 1s 1 slideInFromLeft;

    .cardImage {
      width: 100%;
      height: 10vh;
      object-fit: contain;
      background-color: inherit;
    }
  }
}
