.modal {
  position: fixed;
  bottom: 0px;
  left: 0;
  display: flex;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.85);
  width: calc(100vw - 5px);
  height: 100%;
  margin-left: 5px;
  border-radius: 10px;
  overflow-y: auto;
  z-index: 999;

@media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }

  .modalContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    margin: 0 4vw;
    margin-top: 3vw;
    // margin-left: 5px;
    // margin-right: 5px;

    .details {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .detailsLeft {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;

        .options{
          .optionsBox{
            display: flex;
            gap:5px;
          }
        }

        .sizeOption {
          display: flex; 
          align-items: center;
          border: 2px solid rgba(139, 139, 139, 0.3);
          border-radius: 2vw;
          padding: 0.5vw 1vw;
          margin-right: 8px;
          cursor: pointer;

          .medium {
            font-size: 35px;
            color: #dd1414;
          }
          .large {
            font-size: 45px;
            color: #dd1414;
          }
        }

        .active {
          background-color: #dd1414;
          color: white;
          .medium{
            color:white;
          }
          .large{
            color:white;
          }
        }

        .addToCart {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          gap: 10px;

          .quantityContainer {
            display: flex;
            flex-direction: row;
            gap:5px;
            padding: 5px;
            button{
              color: red;
              border: 1px solid red;
              border-radius: 5px;
              background-color: transparent;
            }
            // border: 1px solid #dd1414;
            // border-radius: 10px;

            // .icon {
            //   color: #dd1414;
            //   cursor: pointer;
            // }
            // .quantity {
            //   width: 25px;
            //   background: none;
            //   border:none;
            //   text-align: center;
            //   justify-content: center;
            //   align-items: center;
            //   font-weight: bold;
            //   color:#dd1414;

            //   &::-webkit-inner-spin-button,&::-webkit-outer-spin-button{
            //     display: none;
            //     margin: 0;
            //   }

            //   &:focus{
            //     outline: none;
            //   }
            // }
          }

          .price {
          }

          .addToCartButton {
            background-color: #dd1414;
            color: white;
            border: none;
            font-weight: 500;
            padding: 1vw;
            border-radius: 2vw;
            cursor: pointer;

            &:hover {
              background-color: #ff1c1c;
            }
          }
        }
      }
      .detailsRight {
        display: flex;
        justify-content: flex-end;
        .close {
          display: flex;
          justify-content: flex-end;
          .closeButton {
            background-color: transparent;
            border: none;
            font-size: 25px;
            cursor: pointer;
            &:hover {
              color: #dd1414;
            }
          }
        }
      }
  .detailsLeft{
        .options{
          padding-bottom: 10px;
        }
      }
    }

    .options {
      ul.ingredients {
        list-style: none;
        padding: 20px;
      }
      ul.ingredients li {
        display: inline;
        margin-right: 5px;
      }
      ul.ingredients li label {
        display: inline-block;
        background-color: rgba(255, 255, 255, 0.9);
        border: 2px solid rgba(139, 139, 139, 0.3);
        color: #adadad;
        border-radius: 25px;
        white-space: nowrap;
        margin: 3px 0px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        transition: all 0.2s;
      }

      ul.ingredients li label {
        padding: 8px 12px;
        cursor: pointer;
      }

      ul.ingredients li label::before {
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        font-weight: 900;
        font-size: 12px;
        padding: 2px 6px 2px 2px;
        transition: transform 0.3s ease-in-out;
      }

      ul.ingredients li input[type="checkbox"]:checked + label::before {
        transition: transform 0.3s ease-in-out;
      }

      ul.ingredients li input[type="checkbox"]:checked + label {
        border: 2px solid #dd1414;
        background-color: #dd1414;
        color: #fff;
        transition: all 0.2s;
      }

      ul.ingredients li input[type="checkbox"] {
        display: absolute;
      }
      ul.ingredients li input[type="checkbox"] {
        position: absolute;
        opacity: 0;
      }
      ul.ingredients li input[type="checkbox"]:focus + label {
        border: 2px solid #ff1c1c;
      }
    }
  }
}
