.popup-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    cursor: pointer;
  }
  
  .popup {
    background: #fff;
    padding: 10px; 
    border-radius: 8px; 
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2); 
    max-width: 90%; 
  
    @media (min-width: 768px) {
      padding: 10px; 
      border-radius: 8px; 
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); 
      max-width: 70%; 
    }
  }
  
  .popup img {
    max-width: 100%;
    max-height: 400px; 
    border-radius: 5px;
    margin-bottom: -5px;
  
    @media (min-width: 768px) {
      max-height: 600px; 
    }
  }
  
