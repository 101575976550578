.home {
  display: flex;

@media (max-width: 768px) {
    width: 100%;
    }

  .homeContainer {
    flex: 19;
    margin-left: 0px;
    align-items: center;
    height: 100vh;
width:100%;

 @media (max-width: 768px) {
      margin-left: 0;
    }

    .homeTitle {
      font-size: 25px;
      color: #dd1414;
      text-align: center;
      cursor: pointer;
    }

    .homeWrapper {
      align-items: center;
      display: flex;
      flex-direction: column;
      // height: 100%;
      .left {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;
        flex: 1;
        padding: 25px;
@media (max-width: 768px) {
          padding: 0;
        }

        h1 {
          font-size: 20px;
        }
        p {
          font-size: clamp(12px, 2vw, 16px);
          color: #333;
        }
        .homeCards {
          display: flex;
          flex-wrap: wrap;
@media (max-width: 768px) {
            flex-direction: column;
          }

          .homeCard {
            flex: 1;
            display: flex;
            align-items: center;
            text-align: center;
            justify-content: center;
            padding: 10px;
            flex-direction: column;
            // width: 25vw;
            min-width: 150px;
            max-width: 250px;
            // min-width: 25vw;
            // border: 1px solid black;

            h1 {
              font-size: 14px;
            }
            p {
              font-size: 12px;
              color: #333;
            }

            .icon {
              font-size: 50px;
              color: #dd1414;
            }
          }
        }
      }
      .right {
        display: flex;
        flex-direction: column;
        flex: 1;
        
        a{
          margin: 0 auto;
        }

        .menuButton {
          // display: flex;
          align-items: center;
          background-color: #dd1414;
          color: white;
          border: none;
          font-size: 14px;
          margin: 20px auto;
          font-weight: 500;
          padding: 10px;
          border-radius: 15px;
          cursor: pointer;
          width: fit-content;

          &:hover {
            background-color: #ff1c1c;
          }
          
        }

   .promo-banner{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          h4{
            margin: 0 10px;
            text-align: center;
            font-size: 12px;
          }
        }

        .discount-banner {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          margin: 20px 0;
          .line {
            height: 2px;
            width: 12vw;
            background-color: red;
            margin: 0 10px;
          }

          .text {
            font-size: 14px;
            font-weight: bold;
            color: red;
          }
          .icon {
            font-size: 1rem;
            color: #dd1414;
          }
        }

        .promoCards {
          display: flex;
          width: 100%;
          justify-content: center;
          flex-wrap: wrap;
          margin: 10px auto;
          margin-bottom: 100px;
        }
      }
    }
  }
}
