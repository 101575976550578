.card {
  justify-content: space-between;
  margin: 0 10px;
  margin-top: 20px;
  max-width: 250px;
//  text-align: center;

//   margin: 5px 10px;

  .cardContainer {
    flex: 1;
    margin: 5px 10px;
    padding: 10px 20px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    border: 2px solid #ffe8e8;
    border-radius: 10px;
    gap:10px;

    &:hover {
      background-color: #ffe8e8;
    }

    // &:active {
    //   border-color: #dd1414;
    // }

    .cardImage {
      width: 100%;
      height: 20vh;
      object-fit: contain;
      background-color: inherit;
    }

    .details {
      display: flex;
      flex-direction: column;
      align-items: center;
      .title {
        font-size: 16px;
        font-weight: 500;
        text-transform: capitalize;
      }
      .price {
        font-size: 12px;
        font-weight: 300;
      }
    }
  }
}
