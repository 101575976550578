.navbar {
  height: 10vh;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
  gap: 10px;

  .wrapper {
    width: 100%;
    // padding: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .items {
      display: flex;
      align-items: center;

      .item {
        padding: 10px;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;

        &:hover {
          background-color: #ffe8e8;
        }

        .icon {
          padding: 10px;
          font-size: 20px;
          color: #dd1414;
        }

        span {
          font-size: 12px;
          font-weight: bold;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #dd1414;
          color:white;
          position: absolute;
          right: 10px;
          top: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
