.privacy-policy-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensures it's on top of other content */
  
    .popup-content {
      background: #fff;
      padding: 20px;
      border-radius: 5px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      text-align: left;
      max-width: 80%;
      height: 75%; /* Set the height to 75% of the viewport height */
      overflow-y: auto; /* Enable vertical scrolling if the content exceeds the height */
      display: flex;
      flex-direction: column;
    }
  
    .popup-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      border-bottom: 1px solid #ccc;
      margin-bottom: 10px;
    }
  
    h2 {
      color: #333;
      font-size: 1.5rem;
      margin: 0;
    }
  
    button {
      background: #ff0000;
      color: #fff;
      border: none;
      padding: 5px 10px;
      border-radius: 5px;
      cursor: pointer;
  
      &:hover {
        background: #cc0000;
      }
    }
  }
  
