.cart {
  .cartBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    background: #252424cc;
    height: 100%;
    width: 100vw;
  }

  .cartContainer {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    background-color: white;
    border-left: 1px solid #999;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 40px);
    min-width: 25vw;
    justify-content: space-between;
    overflow-y: auto;
    .cartTop {
      .cartHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .cartHeaderLeft {
          display: flex;
          h1 {
            font-size: 18px;
          }
        }

        .cartHeaderRight {
          .closeButton {
            background-color: transparent;
            border: none;
            font-size: 25px;
            cursor: pointer;
            &:hover {
              color: #dd1414;
            }
          }
        }
      }

      .cartDelete {
        color: #dd1414;
        cursor: pointer;
      }
    }

    .cartBottom {
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-bottom: 20px;
      .total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        span {
          font-size: 14px;
        }
      }

      .button {
        display: flex;
        justify-content: center;
        button {
          background-color: #dd1414;
          color: white;
          border: none;
          font-weight: 500;
          font-size: 20px;
          padding: 10px 20px;
          border-radius: 15px;
          cursor: pointer;
          width: fit-content;

          &:hover {
            background-color: #ff1c1c;
          }
        }
      }
    }
  }
}
