.menu {
    display: flex; 
  
    .menuContainer {
      flex: 19;
      // margin-left: 75px;
      // margin-right: 20px;
      margin-bottom: 30px;
      align-items: center;
      min-height: 100vh;
      width: 100%;
 
      

 @media (max-width: 768px) {
        margin-left: 0;
      }

      hr {
        margin-top: 10px;
        height: 0;
        border: 0.5px solid #efecec;
      }
  
      .menuWrapper{
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content:center;
        // height: 90vh;
      }
    }
  }
