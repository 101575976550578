.orderItems{
    .cartItems {
        list-style: none;
        padding: 0;
        margin-top: 10px;
        display: flex;
        flex-direction: column;

        .cartItem {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          padding: 10px;
          .itemLeft {
            display: flex;
            flex-direction: row;
            gap: 20px;
            padding-right: 10px;


            img {
              width: 40px;
              height: 40px;
              object-fit: contain;
            }

            .details {
              h1 {
                font-size: 16px;
                text-transform: capitalize;
              }
              .cartDetails {
                font-size: 14px;
                color: #333;
                p{
                  font-size: 12px;
                }
              }
              .price {
                font-size: 12px;
              }
            }
          }
          .itemRight {
            display: flex;
            align-items: center;
            gap:10px;
            button{
              color: red;
              border: 1px solid red;
              border-radius: 5px;
              background-color: transparent;
            }
          }
        }
      }
}
