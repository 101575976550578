.checkout {
  position: fixed;
  top: 0;
  left: 0;
  background: #25242482;
  height: 100%;
  width: 100vw;
  z-index: 9999;
  display: flex;
  align-items: center;

  .checkoutContainer {
    background-color: white;
    height: 75vh;
    width: 80vw;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 50px;
    overflow-y: auto;
    .checkoutTop {
      .checkoutHeader {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .checkoutHeaderLeft {
          h1 {
            font-size: 18px;
            color: #333;
          }
        }
        .checkoutHeaderRight {
          .closeButton {
            background-color: transparent;
            border: none;
            font-size: 25px;
            cursor: pointer;
            &:hover {
              color: #dd1414;
            }
          }
        }
      }
    }

    .checkoutBottom {
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 20px;
      margin-bottom: 50px;
      .bottomLeft {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2vw;
        // padding: 0 20px;

        .contactData {
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: 30px;

          .formInput {
            flex: 1;
            label {
              display: flex;
              align-items: center;
              gap: 10px;
              font-size: 12px;
              margin-bottom: 5px;
            }

            input {
              width: 100%;
              border: 1px solid #cccccc;
              border-radius: 5px;
              outline: none;
              padding: 10px;
              box-sizing: border-box;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }
            input[type="number"] {
              -moz-appearance: textfield;
            }
            .errorMessage {
              color: red;
            }
          }
        }

        .emailInput {
          width: 100%;
          label {
            display: flex;
            align-items: center;
            gap: 10px;
            font-size: 12px;
            margin-bottom: 5px;
          }

          input {
            width: 100%;
            border: 1px solid #cccccc;
            border-radius: 5px;
            outline: none;
            padding: 10px;
            box-sizing: border-box;
          }
          .errorMessage {
            color: red;
          }
        }

        .deliveryData {
          margin-top: 10px;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .deliveryButtons {
            display: flex;
            justify-content: center;
            gap: 3vw;
            .deliveryOption {
              display: flex;
              align-items: center;
              border: 1px solid rgba(139, 139, 139, 0.3);
              border-radius: 2vw;
              padding: 0.5vw 1vw;
              font-size: clamp(14px, 1vw, 20px);
              cursor: pointer;
            }

            .active {
              background-color: #dd1414;
              color: white;
            }
          }

          .deliveryDetails {
            display: flex;
            flex-direction: column;
            gap: 20px;

            .errorMessage {
              color: red;
            }

            .homeNumber {
              width: 100%;

              .errorMessage {
                color: red;
              }
              input {
                width: 100%;
                border: 1px solid #cccccc;
                border-radius: 5px;
                outline: none;
                padding: 10px;
                box-sizing: border-box;
              }

              input::-webkit-outer-spin-button,
              input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }
              input[type="number"] {
                -moz-appearance: textfield;
              }
            }
          }
        }

        .commentsData {
          margin-top: 10px;
          width: 100%;
          .comments {
            label {
              display: flex;
              align-items: center;
              gap: 10px;
              font-size: 12px;
              margin-bottom: 5px;
            }

            textarea {
              width: 100%;
              resize: none;
              outline: none;
              border: 1px solid #cccccc;
              border-radius: 5px;
              padding: 5px;
              box-sizing: border-box;
            }
          }
        }
      }
      .bottomRight {
        flex: 1;

        .acceptTerms {
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding-top: 10px;
          font-size: 14px;
          input {
            margin-right: 5px;
          }
          a {
            text-decoration: none;
          }
          span{
            cursor: pointer;
            &:hover{
              color:#ccc;
            }
          }
          .errorMessage {
            color: red;
          }
        }

        .paymentData {
          padding-top: 10px;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .acceptTOS {
            display: flex;
            flex-direction: column;
            font-size: 14px;
            gap: 5px;

            input {
              margin-right: 5px;
            }
            a {
              text-decoration: none;
            }
            .errorMessage {
              color: red;
            }
          }

  .errorMessage {
            font-size: 14px;
            color: red;
          }

          .bankCards {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 2vw;
            .bankCard {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 75px;
              height: 75px;
              cursor: pointer;

              &:hover {
                border: 1px solid #57ff35;
                border-radius: 5px;
              }
              .logo {
                text-align: center;
                img {
                  width: 100%;
                }
                .title {
                  font-size: 14px;
                }
              }
            }
            .active {
              border: 1px solid #57ff35;
              border-radius: 5px;
            }
          }

          .title {
            font-size: 12px;
            color: #333;
          }

          .paymentButtons {
            display: flex;
            justify-content: center;
            gap: 3vw;
            .paymentOption {
              display: flex;
              align-items: center;
              border: 1px solid rgba(139, 139, 139, 0.3);
              border-radius: 2vw;
              padding: 0.5vw 1vw;
              font-size: clamp(14px, 1vw, 20px);
              cursor: pointer;
            }

            .active {
              background-color: #dd1414;
              color: white;
            }
          }
        }

 .voucherData {
          width: 80%;
          margin: 0 auto;
          margin-top: 10px;
          display: flex;
          flex-direction: column;
          gap:10px;
  
          .vouchers {
            margin-top: 15px;
            label {
              display: flex;
              align-items: center;
              gap: 10px;
              font-size: 12px;
              margin-bottom: 5px;
            }
  
            input {
              width: 100%;
              resize: none;
              outline: none;
              border: 1px solid #cccccc;
              border-radius: 5px;
              padding: 5px;
              box-sizing: border-box;
            }
          }
          button{
            margin: 0 auto;
            background-color: #dd1414;
            color: white;
            border: none;
            font-weight: 500;
            padding: 5px 10px;
            font-size: 12px;
            border-radius: 15px;
            cursor: pointer;
            width: fit-content;
  
            &:hover {
              background-color: #ff1c1c;
            }
          }
          .error{
            margin: 0 auto;
          }
        }

        .tipData {
          padding-top: 10px;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .title {
            font-size: 12px;
            color: #333;
          }

          .tipButtons {
            display: flex;
            justify-content: center;
            gap: 3vw;
            .tipOption {
              display: flex;
              align-items: center;
              border: 1px solid rgba(139, 139, 139, 0.3);
              border-radius: 2vw;
              padding: 0.5vw 1vw;
              font-size: clamp(14px, 1vw, 20px);
              cursor: pointer;
            }

            .active {
              background-color: #dd1414;
              color: white;
            }
          }
        }

        .deliveryData {
          padding-top: 20px;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .errorMessage {
            color: red;
          }

          .title {
            font-size: 12px;
            color: #333;
          }

          select {
            width: 100%;
            border: 1px solid #cccccc;
            border-radius: 5px;
            outline: none;
            padding: 10px;
            box-sizing: border-box;
          }
        }

        h1 {
          font-size: 20px;
          color: #333;
        }

        .totalPrice {
          padding-top: 10px;
          font-size: 14px;
          color: #333;
        }

 .wosp{
          color:red;
          text-align: center;
          padding: 10px;
          font-weight: 900;
        }

        .checkoutButton {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 20px;

          button {
            background-color: #dd1414;
            color: white;
            border: none;
            font-weight: 500;
            padding: 10px 20px;
            font-size: 20px;
            border-radius: 15px;
            cursor: pointer;
            width: fit-content;

            &:hover {
              background-color: #ff1c1c;
            }
&:disabled{
              background-color: #333;
              cursor:not-allowed;
            }
          }
        }
      }
    }
  }
}
