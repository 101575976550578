.errorContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  .errorBox {
    width: 300px;
    height: 150px;
    margin: auto;
    background-color: white;
    border: 1px solid #333;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap:10px;
    align-items: center;
    justify-content: center;

    button {
      background-color: #dd1414;
      color: white;
      border: none;
      font-weight: 500;
      font-size: 20px;
      padding: 10px 20px;
      border-radius: 15px;
      cursor: pointer;
      width: fit-content;

      &:hover {
        background-color: #ff1c1c;
      }
    }
  }
}
