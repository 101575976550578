.list {
  .listCategories {
    .categoriesContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      ul {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        justify-content: center;
        margin: 0;
        padding: 0;

        .categoryItem {
          padding: 1vw;
          // margin-bottom: 15px;
          display: flex;
          flex-direction: column;
          gap: 5px;

          li {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            .icon {
              padding: 10px;
              border: 2px solid #ffe8e8;
              border-radius: 50%;
              font-size: 18px;
              color: #dd1414;

              &:hover {
                background-color: #ffe8e8;
              }
            }

            // .categoryIcon{
            //   padding: 10px;
            //   border: 2px solid #ffe8e8;
            //   border-radius: 50%;
            //   font-size: 18px;
            //   color: #dd1414;
            //   height: 25px;

            //   &:hover {
            //     background-color: #ffe8e8;
            //   }
            // }
          }

          .categoryTitle {
            font-size: 10px;
            font-weight: bold;
            color: #999;
            margin-bottom: 5px;
            text-align: center;
            text-transform: capitalize;
          }
        }

        // .active{
        //   li{
        //     .icon{
        //       color: white;
        //       background-color: #dd1414;

        //       &:hover {
        //         background-color: #dd1414;
        //       }
        //     }
        //   }

        //   .categoryTitle {
        //     font-size: 10px;
        //     font-weight: bold;
        //     color: #dd1414;
        //     margin-bottom: 5px;
        //     text-align: center;
        //   }
        // }
      }
    }
  }
  .listWrapper {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // height: 90vh;
  }
}
