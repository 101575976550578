.sidebarMobile {
  display: none;

//  @media (max-width: 768px) {
    display: block;
    position: absolute;
    z-index: 999;

    .mobile-menu-toggle {
      display: block;
      background: none;
      border: none;
      cursor: pointer;
      font-size: 20px;
      color: #dd1414;
      margin: 10px;

      &.open {
        display: none; // Hide the toggle button when the menu is open
      }
    }

    .mobile-menu {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
      background-color: white;

      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        margin-top: 50px;

        li {
          margin-bottom: 10px;
          margin-top: 10px;
          text-decoration: none;

          .item{
            display: flex;
            justify-content: center;
            gap:10px;
          }

          a {
            text-decoration: none;
            color: #dd1414;
            font-size: 14px;
            font-weight: bold;

            &:hover {
              color: #dd1414;
            }
          }
       }
      }

      &.open {
        display: block; // Show the mobile menu when it's open
      }
    }
 //  }
}

.sidebar {
  display: none;
  flex: 1;
  border-right: 0.5px solid #efecec;
  min-height: 100%;
  width: 75px;
  background-color: white;
  // display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  top: 0;

@media (max-width: 768px) {
    display: none;
  }

  .top {
    /* padding-left: 10px; */
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .item {
        padding: 10px;
        margin-bottom: 15px;

        &:hover {
          background-color: #ffe8e8;
        }

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          cursor: pointer;

          .icon {
            font-size: 18px;
            color: #dd1414;
          }
        }

        .title {
          font-size: 10px;
          font-weight: bold;
          color: #999;
          margin-bottom: 5px;
          text-align: center;
        }
      }
    }
  }

  .bottom {
    /* padding-left: 10px; */
    ul {
      list-style: none;
      margin: 0;
      padding: 0;

      .item {
        padding: 10px;
        margin-bottom: 15px;

        &:hover {
          background-color: #ffe8e8;
        }

        li {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;
          cursor: pointer;

          .icon {
            font-size: 18px;
            color: #dd1414;
          }
        }

        .title {
          font-size: 10px;
          font-weight: bold;
          color: #999;
          margin-bottom: 5px;
          text-align: center;
        }
      }
    }
  }
  .power{
    position: absolute;
    cursor: pointer;
    left: 50px;
    bottom: 50px;
    z-index: 999;
    padding: 5px;
    color:#dd1414;
    border: 2px solid #dd1414;
    border-radius: 5px;

    &:hover{
      background-color: #dd1414;
      color:white;
    }
  }
}
