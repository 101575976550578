.cookie {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: #fff; 
    color: #000; /* Black text */
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 9999999; /* Ensures it's always on top */
  
    /* Center the banner horizontally */
     @media (min-width: 768px) {
    width: 50%;
    margin-left: 25%;
  }
  
    /* Add a subtle box shadow */
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  
    p {
      margin: 0;
      padding: 5px;
    }
  
    a {
      color: #ff0000; /* Red link color */
      text-decoration: none;
  
      &:hover {
        text-decoration: underline;
      }
    }
  
    button {
      background-color: #ff0000; /* Red button background */
      color: #fff; /* White button text */
      border: none;
      padding: 5px 15px;
      border-radius: 5px;
      cursor: pointer;
      margin-left: 10px;
  
      &:hover {
        background-color: #ff3333; /* Darker red on hover */
      }
    }
  }
  
